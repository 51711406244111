import { AxiosProgressEvent, AxiosRequestConfig, Canceler } from 'axios'
import api from './api'
import { PaginatedData, ServerRes } from './api.types'
import {
  CREATE_DOCUMENT,
  CREATE_FUND_DOCUMENT,
  GET_ACCESS_LIST,
  GET_DATA_ROOM,
  GET_FILES,
  GET_FOLDERS,
  GET_FUND_FILES,
  GET_FUND_FOLDERS,
  GET_UPDATES,
  GET_UPDATES_COMMENTS,
  POST_UPDATES_MEDIA,
  SEARCH_DOCUMENTS,
  SEARCH_FUND_DOCUMENTS,
} from './endpoints'
import { sanitizeQueryParams } from '@/helpers/sanitizeQueryParams'

interface ApiRequestConfig extends AxiosRequestConfig {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  abort?: (canceler: Canceler) => void
}

export interface DocumentViewersResDTO {
  id: number
  created: Date
  about: string
  first_name: string
  last_name: string
  avatar: string
  online: boolean
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
}

export type GetDataRoomQueryDTO = {
  page: number
  status?: 'founder' | 'member' | ''
  query?: string
  fund?: string
  startup?: string | number
}
export type GetItemQueryDTO = {
  id: number
  page?: number
  status?: 'Pending' | 'Accepted' | 'Declined'
  name?: string
  startup?: string | number
}
export type PostUpdatesReqDTO = {
  startup?: number
  update?: number
  content: string
}
export type PostUpdatesMediaReqDTO = {
  update: number
  file: string
  video_cover?: string
}

export type GetFilesQueryDTO = {
  page: number
  folder?: number
  sort_order?: 'asc' | 'desc'
  query?: string
  fund?: string
  status?: 'Pending' | 'Accepted' | 'Declined'
  parent?: boolean
  startup?: string
}

export interface GetDataRoomResDTO {
  id: number
  name: string
  about_us: string
  logo: string
  num_folders: number
}
export interface GetFoldersResDTO {
  id: number
  name: string
  is_folder: boolean
  file: string
  color: 'purple' | 'blue' | 'orange' | 'green' | 'primary' | 'secondary'
  created: string
  updated: string
  startup: number
  parent: number
  size: string
  file_count: number

  num_viewers: number
  files?: []

  metadata?: {
    size: string | number
    type: string
  }
}
export interface CreateFolderReqDTO {
  name: string
  is_folder: boolean
  file?: string
  color?: string
  fund?: number
  parent?: number
  id?: number
  startup?: number
  metadata?: {
    size: string | number
    type: string
  }
}
export interface CreateFolderResDTO {
  id: number
  name: string
  is_folder: boolean
  file: string
  color: string
  created: string
  updated: string
  startup: number
  parent: number
}

export interface GetUpdatesResDTO {
  id: number
  user: User
  startup: number
  content: string
  like: boolean
  num_likes: number
  num_comments: number
  media: Media[]
  created: Date
  updated: Date
}

interface Media {
  id: number
  update: number
  file: string
  file_type: string
  metadata: Record<string, any>
}

interface User {
  id: number
  first_name: string
  last_name: string
  avatar: string
  num_connections?: number
  about?: string
}

export interface GetAccess {
  id: number
  status?: string
  created?: string
  user: User
  startup?: number
  investor_group: null | {
    category: string
    id: number
    logo: string
    name: string
  }
}

type RequestAceessReqDTO = {
  startup: number
}
//get data room data
export const GetDataRoomData = async (data: GetDataRoomQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetDataRoomResDTO>>>(
      sanitizeQueryParams(
        `${GET_DATA_ROOM}?page=${data.page}&query=${data.query}&status=${data.status}`
      )
    )
  ).data.data
}
//get folders
export const GetFolders = async (data: GetDataRoomQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetFoldersResDTO>>>(
      sanitizeQueryParams(
        `${data?.startup ? GET_FOLDERS : GET_FUND_FOLDERS}?page=${
          data.page
        }&fund=${data?.fund}&startup=${data?.startup}`
      )
    )
  ).data.data
}

//get single document
export const GetDocument = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<GetFoldersResDTO>>(
      `${data.startup ? CREATE_DOCUMENT : CREATE_FUND_DOCUMENT}${data.id}/`
    )
  ).data.data
}

//get single public document
export const GetPublicDocument = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<GetFoldersResDTO>>(
      `${CREATE_DOCUMENT}${data.name}/${data.id}/`
    )
  ).data.data
}

//create document
export const CreateFile = async (payload: {
  data: CreateFolderReqDTO
  config?: ApiRequestConfig
}) => {
  return await api.post<ServerRes<CreateFolderResDTO>>(
    payload.data.startup ? CREATE_DOCUMENT : CREATE_FUND_DOCUMENT,
    payload.data,
    {
      ...payload.config,
    }
  )
}
export const UpdateDocument = async (
  data: Pick<CreateFolderReqDTO, 'name' | 'id' | 'fund' | 'startup'>
) => {
  const key = data.startup ? 'startup' : 'fund'

  return await api.patch<ServerRes<CreateFolderResDTO>>(
    `${key === 'startup' ? CREATE_DOCUMENT : CREATE_FUND_DOCUMENT}${data.id}/`,
    {
      name: data.name,
      [key]: data[key as keyof typeof data],
    }
  )
}
//delete document
export const DeleteDocument = async (
  data: Pick<CreateFolderReqDTO, 'id' | 'startup'>
) => {
  return await api.delete<ServerRes<CreateFolderResDTO>>(
    data.startup
      ? CREATE_DOCUMENT + data.id + '/'
      : CREATE_FUND_DOCUMENT + data.id + '/'
  )
}

//get files
export const GetFiles = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetFoldersResDTO>>>(
      sanitizeQueryParams(
        `${data?.startup ? GET_FILES : GET_FUND_FILES}?page=${
          data.page
        }&folder=${data.folder}&sort_order=${data.sort_order}&fund=${
          data.fund
        }&parent=${data.parent}&startup=${data.startup}`
      )
    )
  ).data.data
}
//get document viewers
export const GetDocumentViewers = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<DocumentViewersResDTO>>>(
      sanitizeQueryParams(
        `${CREATE_DOCUMENT}${data.id}/viewers/?page=${data.page}`
      )
    )
  ).data.data
}
//download files
export const DownloadDocuments = async (data: GetItemQueryDTO) => {
  return await api.get(`${CREATE_DOCUMENT}${data.id}/download`, {
    headers: {
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
      // "Content-Encoding":''
    },
  })
}
//search documents
export const SearchDocuments = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetFoldersResDTO>>>(
      sanitizeQueryParams(
        `${data.startup ? SEARCH_DOCUMENTS : SEARCH_FUND_DOCUMENTS}?page=${
          data.page
        }&query=${data.query}&folder=${data.folder}&fund=${data.fund}&startup=${
          data.startup
        }`
      )
    )
  ).data.data
}

/***
 *
 * Updates tab
 */
//get updates
export const GetUpdates = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetUpdatesResDTO>>>(
      sanitizeQueryParams(
        `${GET_UPDATES}?page=${data.page}&query=${data.query}&fund=${data.fund}&startup=${data.startup}`
      )
    )
  ).data.data
}
//get updates comments
export const GetUpdatesComments = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetUpdatesResDTO>>>(
      sanitizeQueryParams(
        `${GET_UPDATES_COMMENTS}?page=${data.page}&update=${data.id}`
      )
    )
  ).data.data
}

//post updates
export const PostUpdates = async (data: PostUpdatesReqDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(GET_UPDATES, data)
}

//post updates comments
export const PostUpdatesComment = async (data: PostUpdatesReqDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(GET_UPDATES_COMMENTS, data)
}

//post updates media
export const PostUpdatesMedia = async (
  data: PostUpdatesMediaReqDTO | FormData
) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(POST_UPDATES_MEDIA, data)
}
//delete update
export const DeleteUpdate = async (data: GetItemQueryDTO) => {
  return await api.delete<ServerRes<GetUpdatesResDTO>>(
    GET_UPDATES + data.id + '/'
  )
}
//toggle updates like
export const ToggleUpdatesLike = async (data: GetItemQueryDTO) => {
  return await api.post<ServerRes<GetUpdatesResDTO>>(
    GET_UPDATES + data.id + '/toggle-like/',
    {}
  )
}
//get update
export const GetUpdate = async (data: GetItemQueryDTO) => {
  return (
    await api.get<ServerRes<GetUpdatesResDTO>>(GET_UPDATES + data.id + '/')
  ).data.data
}

/***
 *
 * Access tab
 */
//get updates
export const GetAccessList = async (data: GetFilesQueryDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetAccess>>>(
      sanitizeQueryParams(
        `${GET_ACCESS_LIST}?page=${data.page}&query=${data.query}&fund=${data.fund}&status=${data.status}`
      )
    )
  ).data.data
}

//delete access
export const DeleteAccessReq = async (data: GetItemQueryDTO) => {
  return await api.delete<ServerRes<GetAccess>>(GET_ACCESS_LIST + data.id + '/')
}
//update access to data room
export const UpdateAccessReq = async (data: GetItemQueryDTO) => {
  return await api.patch<ServerRes<GetAccess>>(
    GET_ACCESS_LIST + data.id + '/',
    { status: data.status }
  )
}

export const RequestAccessToDataRoom = async (data: RequestAceessReqDTO) => {
  return await api.post<ServerRes<GetAccess>>(GET_ACCESS_LIST, {
    startup: data.startup,
  })
}
